import { defineStore } from 'pinia';
import { ref }         from 'vue';

import ResellerApi               from '@/api/reseller-api';
import { SelectFieldOptionType } from '@/types/forms';
import { captureError }          from '@/utils/error-logger';
import translate                 from '@/utils/i18n/index';

interface Project extends SelectFieldOptionType {
    link: string
    id: number | null
}


const useResellerStore = defineStore('resellerStore', () => {
    const projectId                 = ref<number | null>(null);
    const selectedCartId            = ref<number | null>(null);
    const defaultCartId             = ref<number | null>(null);
    const resellerPortalIsUpdating  = ref<boolean>(false);
    const showNewProjectWindow      = ref<boolean>(false);
    const projectsList              = ref<Project[]>([]);

    function reset(): void {
        projectId.value = null;
        selectedCartId.value = null;
        defaultCartId.value = null;
        resellerPortalIsUpdating.value = false;
        showNewProjectWindow.value = false;
        projectsList.value = [];
    }

    function selectCartId(project: { projectId: number | null, selectedCartId: number }) {
        selectedCartId.value = project.selectedCartId;
        projectId.value = project.projectId;
    }

    async function setDefaultCart(id: number) {
        defaultCartId.value = id;
    }

    function setResellerPortalUpdating(value: boolean) {
        resellerPortalIsUpdating.value = value;
    }

    function setShowNewProjectWindow(value: boolean) {
        showNewProjectWindow.value = value;
    }

    async function createProject(name: string, markUp: number) {
        return ResellerApi.createProject(name, markUp)
            .then(async response => {
                setResellerPortalUpdating(true);

                const { data } = response;
                const createdProject = {value: data.id, label: data.name, link: data.id.toString(), id: data.id};
                projectsList.value = [...projectsList.value, createdProject];
                await setProject(data.id);

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            })
            .finally(() => {
                setResellerPortalUpdating(false);
            });
    }

    async function deleteProject(id: number) {
        return ResellerApi.deleteProject(id)
            .then(async () => {
                projectsList.value = projectsList.value.filter(project => project.id !== id);
                if (id === projectId.value) {
                    setResellerPortalUpdating(true);

                    if (!defaultCartId.value) {
                        // We need to make sure that the default cart ID is set before selecting it
                        await fetchProjectsList();
                    }

                    await setCart(defaultCartId.value!);
                }
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            }).finally(() => {
                setResellerPortalUpdating(false);
            });
    }

    async function setProject(id: number) {
        return ResellerApi.setActiveProject(id)
            .then(res => {
                selectCartId({ selectedCartId: res.data.selectedCartId, projectId: res.data.id });

                return res;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function setCart(id: number) {
        return ResellerApi.setActiveCart(id)
            .then(res => {
                selectCartId({ selectedCartId: res.data.selectedCartId, projectId: res.data.id });

                return res;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchProjectsList() {
        return ResellerApi.getResellerHeaderProjectsList()
            .then(res => {
                selectCartId({ selectedCartId: res.data.selectedCartId, projectId: res.data.id });

                const { data }      = res;
                const projects      = data.availableProjects;
                projectsList.value  = [
                    {
                        value: 0,
                        label: translate('reseller.header.defaultProject', 'cart'),
                        link:  '',
                        id:    null,
                    },
                ];

                projects.forEach((project) => {
                    projectsList.value.push({
                        value: project.id,
                        label: project.name,
                        id:    project.id,
                        link:  project.id.toString(),
                    });
                });

                defaultCartId.value = data.defaultCartId;

                return {
                    options:      projectsList,
                    responseData: data,
                };
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    return {
        projectId,
        selectedCartId,
        resellerPortalIsUpdating,
        showNewProjectWindow,
        projectsList,
        defaultCartId,
        reset,
        setDefaultCart,
        selectCartId,
        setResellerPortalUpdating,
        setShowNewProjectWindow,
        createProject,
        deleteProject,
        setProject,
        setCart,
        fetchProjectsList,
    };
});

export default useResellerStore;
