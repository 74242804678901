import axios   from '@/api/api';
import {
    CustomerProjectCreatedResponse,
    CustomerProjectsList,
    ProjectDetails,
    ResellerHeaderProjectsList,
}               from '@/types/reseller';
import getRoute from '@/utils/routing';

class ResellerApi {
    async getProjectsList(page = 1) {
        return axios.get<CustomerProjectsList>(
            getRoute(
                'api_v1_resellers_customer_projects_list',
                { page },
            ),
        );
    }

    async getResellerHeaderProjectsList() {
        return axios.get<ResellerHeaderProjectsList>(
            getRoute(
                'api_v1_resellers_customer_projects_header',
            ),
        );
    }

    async setActiveProject(id: number) {
        return axios.post<ResellerHeaderProjectsList>(
            getRoute(
                'api_v1_resellers_customer_projects_select',
                { id },
            ),
        );
    }

    async setActiveCart(id: number) {
        return axios.post<ResellerHeaderProjectsList>(
            getRoute(
                'api_v1_resellers_carts_select',
                { id },
            ),
        );
    }

    async createProject(name: string, markUp: number) {
        return axios.post<CustomerProjectCreatedResponse>(
            getRoute('api_v1_reseller_project_create'),
            { markUp: markUp.toString(), name },
        );
    }

    async getProjectDetails(projectId: number) {
        return axios.get<ProjectDetails>(
            getRoute('api_v1_resellers_customer_projects_details', { id: projectId }),
        );
    }

    async editProject(name: string, markUp: string, projectId: number) {
        return axios.put<ProjectDetails>(
            getRoute('api_v1_resellers_customer_projects_edit', {id: projectId}),
            { markUp, name },
        );
    }

    async deleteProject(projectId: number) {
        return axios.delete(getRoute('api_v1_resellers_customer_projects_delete', {id: projectId}));
    }
}

export default (new ResellerApi());
